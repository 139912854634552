<template>
  <div class="guildManagePage">
      <div class="headFlexBox">
        <div class="headBoxText">公会管理</div>
        <div>
          <el-button type="text" icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </div>
      </div>
      <el-card class="box-card">
        <div>
          <tablePage
            :tableData="tableData"
            :currentPage="currentPage"
            :height="tableHeight"
            :isHeadColor="false"
            :total="total"
            ref="tableList"
            @current-change="currentChange"
            v-loading="loading"
          >
            <el-table-column prop="id" label="id"></el-table-column>
            <el-table-column prop="name" label="名字"></el-table-column>
            <el-table-column prop="description" label="备注"></el-table-column>
            <el-table-column prop="created_at" label="创建时间"></el-table-column>
            <el-table-column prop="created_at" label="更新时间"></el-table-column>
            <el-table-column label="操作" min-width="100px" >
              <template slot-scope="{row}">
                <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                <el-button class="cus_button" type="text" @click="handleDel(row)">删除</el-button>
              </template>
            </el-table-column>
          </tablePage>
        </div>
      </el-card>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogguildForm" :show-close="false">
      <el-form :model="form" ref="form" label-position="right" label-width="122px" :rules="rules">
        <el-form-item label="名字：" prop="name" :error="errorMessage?errorMessage.name:''">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="form.description" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="form_btn" type="primary" @click="handleStore(dialogStatus)">确 定</el-button>
      </div>
    </el-dialog>
    <div class="dialogDelBox">
      <el-dialog title="删除提醒" :visible.sync="dialogDel" :show-close="false">
        <div class="dialogDelText">
          您确定要删除公会“{{guildName}}”吗
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDel = false">取 消</el-button>
          <el-button class="form_btn" type="primary" @click="defineDel">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import authRouter from "mixins/authRouter"
import tableHeight from "mixins/tableHeight";
import tablePage from "components/tablePage";
import api from "@/api/guildManageapi";
export default {
  name: "guildpage",
  components: {
    tablePage,
  },
  mixins: [tableHeight,authRouter],
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("名称不能为空"));
      } else {
        callback();
      }
    };
    return {
      guildName:'',
      dialogDel:false,
      errorMessage:'',
      loading:true,
      currentPage: 1,
      total: 50,
      isHeadColor: false,
      tableData: [],
      dialogguildForm: false,
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "新增",
      },
      form: {
        name: "",
        description: "",
      },
      rules: {
        name: [{ validator: checkName, trigger: "blur" }],
      },
      id: null,
    };
  },
  created() {
    this._getList();
  },
  methods: {
    //进入页面加载表格内容
    async _getList() {
      const data = await api.guildpageApi({ page: this.currentPage });
      if (data.status_code === 200) {
        this.tableData = data.message;
        this.loading = false
      }
    },
    currentChange(val) {
      this.currentPage = val;
      this.loading = true;
      this._getList()
    },
    //公会管理新增
    handleAdd() {
      this.dialogStatus = "create";
      this.dialogguildForm = true;
      this.$nextTick(() => {
        this.form = {
          name: "",
          description: "",
        };
        this.$refs.form.resetFields();
        this.$refs.form.clearValidate();
      });
    },
    //公会管理新增公会表单验证
    handleStore(val) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (val == "create") {
            this._submitForm();
          } else if (val == "update") {
            this._updataForm();
          }
        } else {
          return false;
        }
      });
    },
    //公会管理新增公会表单提交请求
    async _submitForm() {
      const { status_code, message } = await api.submitForm(this.form);
      if (status_code === 200) {
        this.$message({
          message: message,
          type: "success",
        });
        this.dialogguildForm = false;
        this._getList();
      } else if (status_code === 422) {
        this.errorMessage = message
      }
    },
    //编辑按钮
    handleEdit(row) {
      let { id, name, description } = row;
      this.dialogStatus = "update";
      this.dialogguildForm = true;
      this.form = {
        id,
        name,
        description,
      };
    },
    //公会管理编辑公会表单提交请求
    async _updataForm() {
      const { status_code, message } = await api.updataForm(this.form);
      if (status_code === 200) {
        this.$message({
          message: message,
          type: "success",
        });
        this.dialogguildForm = false;
        this._getList();
      } else if (status_code === 422) {
        this.errorMessage = message
      }
    },
    handleDel(row) {
      this.dialogDel = true
      this.id = row.id;
      this.guildName = row.name
    },
    async defineDel() {
      const data = await api.guilDel(this.id);
      if (data.status_code === 200) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.dialogDel = false
        this._getList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./css//headCss.less";
@import "./css/cusDialog.less";
.guildManagePage /deep/ .cus_button {
  color: #666666;
}
.guildManagePage /deep/ .form_btn.el-button {
  width: 85px;
  height: 32px;
  border: none;
  font-size: 14px;
  border-radius: 2px;
}
.guildManagePage /deep/ .el-button--primary {
  background: linear-gradient(270deg, #FE7949 0%, #F74949 100%);
  box-shadow: 0px 3px 6px 0px rgba(244, 81, 11, 0.3);
}
.guildManagePage /deep/ .el-dialog__body {
  padding: 0 20px 0 0;
}
.guildManagePage /deep/.el-form-item__label {
  padding: 0;
}
.guildManagePage /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.guildManagePage /deep/ .el-dialog__header {
  padding: 17px 20px 17px;
  margin-bottom: 10px;
  text-align: center;
}
.guildManagePage /deep/ .el-form-item {
  margin-bottom: 0px;
}
.guildManagePage /deep/ .el-form-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.guildManagePage /deep/ .el-form-item__label {
  line-height: 34px;
}
.guildManagePage /deep/ .el-form-item__content {
  line-height: 34px;
}
.guildManagePage /deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
  width: 300px;
}
.guildManagePage /deep/ .el-dialog__footer {
  padding: 10px 20px 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.guildManagePage /deep/ .cus_button span{
  border-left: 1px solid #e3e3e3;
  padding-left: 12px;
}
</style>